export default {
    /** 登陆/注册/获取验证码/找回密码/登出 */
    login: {
        url: '/api/login/',
        methods: 'post'
    },
    register: {
        url: '/api/register/',
        method: 'post'
    },
    findPassword: {
        url: '/api/findPassword/',
    },
    getLoginCode: {
        url: '/api/getLoginCode/',
    },
    logout: {
        url: '/api/logout/',
    },
    userInfo: {
        url: '/api/userInfo/',
    },
    /** 博客相关 */
    getPostsList: {
        url: '/api/posts/',
    },
    createPost: {
        url: '/api/posts/create/',
        method: 'post'
    },
    deletePost: function (id) { return ({
        url: "/api/posts/".concat(id, "/delete/"),
        method: 'DELETE'
    }); },
    postDetail: function (id) { return ({
        url: "/api/posts/".concat(id, "/"),
    }); }
};
