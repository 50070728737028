var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Loadable from './loadable';
export var renderRouter = function (config) {
    return Object.entries(config).map(function (item) {
        var props = {
            exact: true,
            path: item[0]
        };
        if (item[1] instanceof Object) {
            if (item[1].exact === false) {
                props.exact = false;
            }
            if (item[1].component) {
                props.component = Loadable(item[1]);
            }
            if (item[1].redirect) {
                props.render = function () { return React.createElement(Redirect, { to: item[1].redirect }); };
            }
        }
        else {
            props.component = Loadable(item[1]);
        }
        return React.createElement(Route, __assign({}, props, { key: props.path }));
    });
};
export * from './config';
