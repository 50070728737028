var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import qs from 'qs';
import axios from 'axios';
// import cookie from 'js-cookie'
import { message, Modal } from 'antd';
import { compile } from 'path-to-regexp';
import API from './api';
import { GET_ARRAY_URL } from './getArrayUrl';
// 单设备登录错误处理：弹窗只显示一次
var show403Modal = true;
// 是否展示错误信息后跳转
var show403ModalForUrl = true;
var option = {
    // proxy: undefined,
    baseURL: undefined
    // withCredentials: true,
    // timeout: +process.env.REQUEST_TIMEOUT || 180000 // 超时时间
};
// if (process.env.LOCAL) {
//   // options.proxy = {
//   //   host: '127.0.0.1',
//   //   port: 8616
//   // }
//   option.baseURL = 'http://localhost:8616'
// }
var instance = axios.create(option);
var bodyEncode = {
    'application/json': JSON.stringify,
    'application/x-www-form-urlencoded': qs.stringify
};
message.config({ maxCount: 1 });
// 发送请求前，对数据进行处理
var processRequest = function (config) {
    /*
    if (AUTH_TYPE === 'token' && !/login/register/.test(config.url)) {
      const token = cookie.get('token') || localStorage.getItem('token')
      config.headers.Authorization = `Bearer ${token}`
    }
    //*/
    return config;
};
// 请求错误处理
var processRequestError = function (error) {
    // TODO
    Promise.reject(error);
};
axios.interceptors.response.use(function (response) { return response; }, function (error) {
    if (error.code === 'ECONNABORTED') {
        message.error('接口请求超时，请检查网络连接或者刷新页面重试！');
    }
    return error;
});
// 请求返回成功后，对返回数据进行处理
var processResponse = function (res) {
    if (res &&
        res.data &&
        res.data.error + '' === '2' &&
        res.config.url !== API.userInfo.url) {
        message.error('未登录，请先登录');
        window.location.href = '/data-label/login';
    }
    return __assign(__assign({}, res.data), { failed: res.data.error !== 0, success: res.data.error === 0, msg: res.data.msg || res.data.message || '' });
};
// 响应错误处理
var processResponseError = function (error) {
    if (error.response) {
        /********************************* 处理单设备登录问题  START *********************************/
        if ((error.response.status === 403 &&
            error.response.data &&
            error.response.data.code === 10403) ||
            (error.response.status === 302 &&
                error.response.data &&
                error.response.data.code === 10302)) {
            // 403 错误仅提示信息，不做任何处理
            if (error.response.data.url && show403ModalForUrl) {
                Modal.error({
                    title: error.response.data.msg,
                    okText: '确定',
                    onOk: function () {
                        window.location.href = error.response.data.url;
                    }
                });
                show403ModalForUrl = false;
            }
            if (!error.response.data.url && show403Modal) {
                Modal.error({
                    title: error.response.data.msg,
                    okText: '确定'
                });
                show403Modal = false;
            }
            return Promise.reject(error);
        }
        /********************************* 处理单设备登录问题  END *********************************/
        if (error.response.status) {
            message.error('http error status: ' + error.response.status);
        }
    }
    if (error.message === 'timeout of 3000ms exceeded') {
        message.error('timeout of 3000ms exceeded, please try again');
    }
    // TODO
    Promise.reject(error);
    // 上报异常
    return {
        failed: true,
        success: false,
        msg: error.message
    };
};
// 请求预处理
instance.interceptors.request.use(processRequest, processRequestError);
// 响应拦截
// instance.interceptors.response.use(processResponse, processResponseError)
/**
 * 发送请求
 * /api/v1/foo/bar/:id => request(api.xxx, data, {id: 1})
 * /api/v1/foo/bar?id=1 => request(api.xxx, {id: 1})
 * @param api 对象 {url, method}，见 api.ts
 * @param data 需要发送的数据，可以是 querystring(get) 也可以是 body(post, put, ...)
 * @param headers http head
 * @param urlParams 组成 url 的变量
 * @param options 额外的配置选项，同 axios
 */
var request = function (api, data, headers, options, urlParams) {
    // 测试接口超时
    /*
    if (api.url === 'xxx') {
      // mocky-delay=20000ms 表示延迟 20s
      return instance.get(
        'http://www.mocky.io/v2/5d4d18f13300004b44337534?mocky-delay=20000ms'
      )
    }
    //*/
    // if (process.env.MOCK === '1' && process.env.NODE_ENV === 'development') {
    //   console.log('%c准备 mock 请求:', 'background:#000;color:#bada55')
    //   console.log(`%c${api.url}`, 'color:red')
    //   const rules = mock[api.url]
    //   if (rules) {
    //     const res = mockjs.mock(rules)
    //     console.log(res)
    //     return res
    //   } else {
    //     console.log('%c不存在 mock 规则:', 'background:#000;color:#bada55')
    //     console.log(
    //       `%c请检查 /src/services/mock.ts 是否配置了 ${api.url} 的规则`,
    //       'color:red'
    //     )
    //   }
    // }
    if (urlParams) {
        api = __assign(__assign({}, api), { url: compile(api.url)(urlParams) });
    }
    var config = __assign(__assign({ headers: __assign({ 'Content-Type': 'application/x-www-form-urlencoded' }, headers) }, options), api);
    config.method = config.method || 'GET';
    if (/get/i.test(config.method)) {
        config.params = data;
        if (GET_ARRAY_URL.includes(api.url)) {
            // get请求传数组
            config.paramsSerializer = function () {
                return qs.stringify(data, { arrayFormat: 'repeat' });
            };
        }
    }
    else {
        config.data =
            data instanceof FormData
                ? data
                : bodyEncode[config.headers['Content-Type']].call(null, data);
    }
    return instance(config);
};
export { request };
