import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';
// 按需加载 loading 效果
var Loading = function () {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    useEffect(function () {
        var timer = setTimeout(function () {
            setLoading(true);
        }, 200);
        return function () { return clearTimeout(timer); };
    });
    if (loading) {
        return (React.createElement("div", { className: "ball-beat" },
            "loading...",
            React.createElement("div", null),
            React.createElement("div", null),
            React.createElement("div", null)));
    }
    else {
        return null;
    }
};
var AsyncComponent = loadable(function (props) { return import("@/modules/".concat(props.path)); }, {
    fallback: React.createElement(Loading, null)
});
var Loadable = function (path) { return function () { return React.createElement(AsyncComponent, { path: path }); }; };
export default Loadable;
