import { init } from '@rematch/core';
import createLoadingPlugin from '@rematch/loading';
import * as models from './models';
var loadingPlugin = createLoadingPlugin({});
var store = init({
    models: models,
    plugins: [loadingPlugin]
});
window.store = store; // 将store赋值给window，方便线上问题调试
export { store };
