import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import { renderRouter, mainRouters } from '@/routers';
import './App.css';
function App() {
    return (React.createElement(Provider, { store: store },
        React.createElement(Router, null,
            React.createElement(Switch, null, renderRouter(mainRouters)))));
}
export default App;
// export default process.env.NODE_ENV === 'development' ? hot(App) : App
